import React, { useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import PDFModal from './PDFModal'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import PersonIcon from '@mui/icons-material/Person'

import api from '../../services/api'
import Alert from '../../components/Alert'
import Question from '../../components/Question'
import Table from '../../components/Table'

import * as styles from './styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder'
// ]

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const restructureData = inputData => {
  const output = []
  // Filtrar os principais títulos
  const mainTitles = inputData.filter(item => item.options.length === 0)

  mainTitles.forEach(title => {
    const sectionNumber = title.group.split('.')[0] // Pega o número do título principal

    const relatedData = inputData.filter(
      item => item.group.startsWith(sectionNumber) && item.options.length !== 0
    )

    output.push({
      group: title.group.replace(/^\d+\.\d+ /, ''), // Remove números do título
      statement: title.statement,
      data: relatedData
    })
  })

  return output
}

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const index = () => {
  const theme = useTheme()

  const [value, setValue] = React.useState(0)
  const [questions, setQuestions] = React.useState([])
  const [respForm, setRespForm] = React.useState([])

  const [filter, setFilter] = React.useState(false)

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const [pdfString, setPdfString] = React.useState('')
  const [isOpenPdfModal, setIsOpenPdfModal] = React.useState(false)

  const [uniqueId, setUniqueId] = React.useState(null)
  const [deleteUuid, setDeleteUuid] = React.useState(null)
  const [rows, setRows] = React.useState([])

  const [loading, setLoading] = React.useState(false)

  const [openModal, setOpenModal] = React.useState(false)
  const [companyUuid, setCompanyUuid] = React.useState(null)
  const [personName, setPersonName] = React.useState([])
  const [listSpecialists, setListSpecialists] = React.useState([])
  const [listSpecialistAssignments, setListSpecialistAssignments] =
    React.useState([])

  const handleChange = (_event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const filterQuestions = (list, label) => {
    const temp = list.find(e =>
      e.group.toLowerCase().includes(label.toLowerCase())
    )

    return temp
  }

  const filterResp = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    return tempData ? tempData.option_uuid : ''
  }

  const filterEvidence = questionUuid => {
    const tempData = respForm.find(e => e.question_uuid === questionUuid)

    if (tempData && tempData?.evidence && tempData.evidence !== null) {
      return tempData?.evidence
    } else {
      return null
    }
  }

  const formatPhoneNumber = number => {
    const strNumber = number.toString()

    if (strNumber.length === 13) {
      return `+${strNumber.slice(0, 2)} (${strNumber.slice(
        2,
        4
      )}) ${strNumber.slice(4, 9)}-${strNumber.slice(9, 13)}`
    } else if (strNumber.length === 12) {
      return `+${strNumber.slice(0, 2)} (${strNumber.slice(
        2,
        4
      )}) ${strNumber.slice(4, 8)}-${strNumber.slice(8, 12)}`
    } else if (strNumber.length === 11) {
      return `+55 (${strNumber.slice(0, 2)}) ${strNumber.slice(
        2,
        7
      )}-${strNumber.slice(7, 11)}`
    } else if (strNumber.length === 10) {
      return `+55 (${strNumber.slice(0, 2)}) ${strNumber.slice(
        2,
        6
      )}-${strNumber.slice(6, 10)}`
    } else if (strNumber.length === 9) {
      return `+55 (XX) ${strNumber.slice(0, 4)}-${strNumber.slice(4, 8)}`
    } else if (strNumber.length === 8) {
      return `+55 (XX) ${strNumber.slice(0, 3)}-${strNumber.slice(3, 7)}`
    } else {
      return 'Número inválido'
    }
  }

  const formatCNPJ = n => {
    return `${n.slice(0, 2)}.${n.slice(2, 5)}.${n.slice(5, 8)}/${n.slice(
      8,
      12
    )}-${n.slice(12)}`
  }

  const columns = [
    {
      field: 'Ações',
      headerName: 'Ações',
      width: 140,
      renderCell: params => (
        <div>
          <IconButton
            title="Visualizar"
            onClick={() => {
              handleSubmit(params.row.uuid, params.row.answers)
            }}
          >
            <VisibilityIcon size={24} color="#2f2fd3" />
          </IconButton>
          <IconButton
            title="Excluir"
            onClick={() => {
              handleSubmit(params.row.user_uuid, params.row.answers, 'delete')
            }}
          >
            <DeleteIcon size={24} color="#2f2fd3" />
          </IconButton>
          <IconButton
            title="Atribuir"
            onClick={() => {
              setCompanyUuid(params.row.uuid)
              setOpenModal(true)
            }}
          >
            <PersonIcon size={24} color="#2f2fd3" />
          </IconButton>
        </div>
      )
    },
    { field: 'trade_name', headerName: 'Empresa', width: 200 },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 150,
      renderCell: params => {
        if (params.row.cnpj) {
          return formatCNPJ(params.row.cnpj)
        }
      }
    },
    { field: 'full_name_responsible', headerName: 'Responsável', width: 200 },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 150,
      renderCell: params => {
        if (params.row.phone) {
          return formatPhoneNumber(params.row.phone)
        }
      }
    },
    { field: 'email_responsible', headerName: 'Email', width: 250 },
    {
      field: 'answers',
      headerName: 'Resposta',
      width: 100,
      renderCell: params => {
        if (params.row.answers && params.row.answers.length > 0) {
          return <CheckIcon style={{ margin: 'auto', color: 'green' }} />
        } else {
          return <CloseIcon style={{ margin: 'auto', color: 'red' }} />
        }
      }
    },
    {
      field: 'assigned',
      headerName: 'Atribuido',
      width: 100,
      renderCell: params => (
        <Typography style={{ margin: 'auto' }}>
          {filterAssigned(params.row.uuid)}
        </Typography>
      )
    }
  ]

  const handleSubmit = async (uuid, answer, type = 'show') => {
    if (type === 'show') {
      if (answer.length > 0) {
        setMsg('')
        setUniqueId(uuid)
        // Fazer requisição para as respostas
        try {
          setTypeAlert('loading')
          setOpenAlert(true)

          const response = await api.get('/answer', {
            params: {
              all: true,
              atributo: 'company_uuid',
              value: uuid
            }
          })

          if (response.data) {
            setRespForm(response.data)
            setOpenAlert(false)
          }
        } catch (err) {
          setMsg('Não foi possível adquirir as Respostas!')
          setTypeAlert('error')
        }
      } else {
        setMsg('Formulário ainda não preenchido!')
        setTypeAlert('alert')
        setOpenAlert(true)
      }
    } else {
      setDeleteUuid(uuid)
      setMsg('Essa operação é irreverssível, deseja continuar?')
      setTypeAlert('delete')
      setOpenAlert(true)
    }
  }

  const handleDelete = async () => {
    try {
      setMsg('')
      setTypeAlert('loading')

      await api.delete('/user/', { params: { uuid: deleteUuid } })

      setMsg('Usuário excluido com sucesso!')
      setTypeAlert('success')
    } catch (err) {
      setMsg('Erro ao excluir o usuário, tente novamente!')
      setTypeAlert('error')
    }
  }

  // function isWithin10Minutes(datetime) {
  //   const now = new Date()
  //   const differenceInMilliseconds = now - datetime
  //   const differenceInMinutes = differenceInMilliseconds / (1000 * 60)
  //   return differenceInMinutes < 60
  // }

  const filterSpecialist = async () => {
    try {
      setTypeAlert('loading')
      setOpenAlert(true)

      const response = await api.get('/role/', { params: { all: true } })
      if (response?.data) {
        const temp = response.data.find(e => e.role_name === 'specialist')
        if (temp) {
          const response = await api.get('/user/', {
            params: { all: true, atributo: 'role_uuid', value: temp.uuid }
          })
          if (response?.data) {
            setListSpecialists(response.data)
            setOpenAlert(false)
          }
        }
      }
    } catch (err) {
      setMsg(null)
      setTypeAlert('error')
    }
  }

  const specialistAssignment = async () => {
    if (personName && personName.length > 0 && companyUuid) {
      try {
        setMsg('')
        setTypeAlert('loading')
        setOpenAlert(true)

        const sendData = personName.map(value => ({
          specialist_uuid: value,
          company_uuid: companyUuid
        }))

        await api.post('/specialistassignment', sendData)

        setMsg('Especialistas atribuidos com sucesso!')
        setTypeAlert('success')
      } catch (err) {
        if (err?.response?.data) {
          setMsg(err.response.data.detail[0].msg)
        } else {
          setMsg('Não foi possível atribuir os especialistas!')
        }
        setTypeAlert('error')
      }
    }
  }

  const loadData = async () => {
    try {
      setTypeAlert('loading')
      setOpenAlert(true)

      let resp = await api.get('/question/', { params: { all: true } })
      if (resp) {
        const temp = restructureData(resp.data)
        setQuestions(temp)
      }

      const tempRow = localStorage.getItem('inovaMT@/data')
      // const hour = localStorage.getItem('inovaMT@/hour')

      if (tempRow) {
        setRows(JSON.parse(tempRow))
        // const datetime = new Date(hour)
        // const resultado = isWithin10Minutes(datetime)

        setOpenAlert(false)
      }

      // resp = await api.get('/specialistassignment', {
      //   params: { all: true }
      // })

      // if (resp?.data) {
      //   setListSpecialistAssignments(resp.data)
      // }

      resp = await api.get('/company/', { params: { all: true } })

      if (resp) {
        localStorage.setItem('inovaMT@/data', JSON.stringify(resp.data))
        localStorage.setItem('inovaMT@/hour', new Date())

        if (tempRow !== JSON.stringify(resp.data)) {
          setRows(resp.data)
        }

        setOpenAlert(false)
      }
    } catch (err) {
      setMsg('Não foi possível adquirir os dados!')
      setTypeAlert('error')
    }
  }

  const loadFile = (base64File, fileType) => {
    const byteCharacters = atob(base64File)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: fileType })
    const blobUrl = URL.createObjectURL(blob)
  
    window.open(blobUrl)
    URL.revokeObjectURL(blobUrl)
  }

  const handlePdfView = (evidence) => {
    if (evidence != null) {
      // setPdfString(evidence)
      // setIsOpenPdfModal(true)
      loadFile(evidence, 'application/pdf')
    }
  }

  const moveProx = () => {
    setValue(value + 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const moveAnt = () => {
    setValue(value - 1)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  const filterAssigned = uuid => {
    const filteredData = listSpecialistAssignments.filter(
      item => item.company_uuid === uuid
    )

    if (filteredData && filteredData.length > 0) {
      return filteredData.length
    } else {
      return 0
    }
  }

  useEffect(() => {
    filterSpecialist()
    loadData()
  }, [])

  return (
    <Box>
      {uniqueId !== null ? (
        <Box sx={{ display: 'flex', gap: 1, maxWidth: '100px' }}>
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => setUniqueId(null)}
          >
            Voltar
          </Button>
        </Box>
      ) : (
        <>
          {filter ? (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                width: '300px',
                position: 'absolute',
                top: '70px',
                right: '30px'
              }}
            >
              <Button
                fullWidth
                sx={{ marginTop: 1 }}
                size="large"
                type="submit"
                variant="contained"
                onClick={() => setFilter(false)}
              >
                Listar Todos
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                width: '450px',
                position: 'absolute',
                top: '70px',
                right: '30px'
              }}
            >
              <Button
                fullWidth
                sx={{ marginTop: 1 }}
                size="large"
                type="submit"
                variant="contained"
                onClick={() => setFilter(true)}
              >
                Listar Respondidos
              </Button>
              <Button
                fullWidth
                sx={{ marginTop: 1 }}
                size="large"
                type="submit"
                variant="contained"
                onClick={async() => {
                  try {
                    setLoading(true)
                    const response = await api.get('/company/companies_with_answers_csv')
                    const blob = new Blob([response.data], { type: 'text/csv' })
                    const url = URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.download = 'empresas.csv'
                    link.click()
                    URL.revokeObjectURL(url)
                    setLoading(false)                   
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                Baixar CSV
              </Button>
            </Box>
          )}
          {loading && (
            <Box
              sx={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: '#00000077', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 50 }}
            >
              <Box sx={{ 
                display: 'inline-block',
                color: 'white',
                height: '32px',
                width: '32px',
                borderRadius: '50%',
                borderWidth: '4px',
                borderStyle: 'solid',
                borderColor: 'currentColor',
                borderRightColor: 'transparent',
                animation: 'spin 1.5s linear infinite',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' },
                },
                verticalAlign: '-0.125em',
                '@media (prefers-reduced-motion)': {
                  animation: 'none',
                },}}>
              </Box>
            </Box>
          )}
        </>
      )}
      {uniqueId !== null ? (
        questions.length > 0 && (
          <Box sx={styles.Box}>
            <Box
              sx={{
                maxWidth: {
                  xs: 320,
                  sm: '100vw',
                  md: '100vw',
                  lg: '100vw',
                  xl: '100vw'
                }
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Auto Diagnóstico" {...a11yProps(0)} />
                <Tab label="Conhecimento do Mercado" {...a11yProps(1)} />
                <Tab label="Liderança Transformadora e Intenção de Inovar" {...a11yProps(2)} />
                <Tab label="Gestão de Projetos e Pessoas para inovação" {...a11yProps(3)} />
                <Tab label="Gestão estratégica de tecnologias e organicidade" {...a11yProps(4)} />
                <Tab label="Avaliação de desempenho e resultados de inovação" {...a11yProps(5)} />
                {/* <Tab label="Melhoria" {...a11yProps(6)} /> */}
              </Tabs>
            </Box>

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <>
                  <Question
                    title={
                      filterQuestions(questions, 'Auto Diagnóstico')
                        .group.replace(/^\d\. /, '') || ''
                    }
                    value={
                      filterQuestions(questions, 'Auto Diagnóstico')
                        .statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(
                    questions,
                    'Auto Diagnóstico'
                  ).data.map((q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio-zero"
                        resp={filterResp(q.uuid)}
                        setResp={() => {}}
                        typeButtonFile={true}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={handlePdfView}
                      />
                      <br />
                    </>
                  ))}
                </>
              </TabPanel>

              <TabPanel value={value} index={1} dir={theme.direction}>
                <>
                  <Question
                    title={filterQuestions(questions, 'Conhecimento do Mercado').group || ''}
                    value={
                      filterQuestions(questions, 'Conhecimento do Mercado').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Conhecimento do Mercado').data.map(
                    (q, index) => (
                      <>
                        <Question
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel>

              <TabPanel value={value} index={2} dir={theme.direction}>
                <>
                  <Question
                    title={
                      filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').group || ''
                    }
                    value={
                      filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Liderança Transformadora e Intenção de Inovar').data.map(
                    (q, index) => (
                      <>
                        <Question
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel>

              <TabPanel value={value} index={3} dir={theme.direction}>
                <>
                  <Question
                    title={filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').group || ''}
                    value={
                      filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Gestão de Projetos e Pessoas para inovação').data.map(
                    (q, index) => (
                      <>
                        <Question
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel>

              <TabPanel value={value} index={4} dir={theme.direction}>
                <>
                  <Question
                    title={filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').group || ''}
                    value={
                      filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Gestão estratégica de tecnologias e organicidade').data.map(
                    (q, index) => (
                      <>
                        <Question
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel>

              <TabPanel value={value} index={5} dir={theme.direction}>
                <>
                  <Question
                    title={
                      filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação')
                        .group || ''
                    }
                    value={
                      filterQuestions(questions, 'Avaliação de desempenho e resultados de inovação')
                        .statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(
                    questions,
                    'Avaliação de desempenho e resultados de inovação'
                  ).data.map((q, index) => (
                    <>
                      <Question
                        key={index}
                        title={q.statement}
                        options={q.options}
                        uuid={q.uuid}
                        type="radio"
                        resp={filterResp(q.uuid)}
                        setResp={() => {}}
                        typeButtonFile={true}
                        evidence={filterEvidence(q.uuid)}
                        updateEvidence={handlePdfView}
                      />
                      <br />
                    </>
                  ))}
                </>
              </TabPanel>

              {/* <TabPanel value={value} index={6} dir={theme.direction}>
                <>
                  <Question
                    title={filterQuestions(questions, 'Melhoria').group || ''}
                    value={
                      filterQuestions(questions, 'Melhoria').statement || ''
                    }
                    type="text"
                  />
                  <br />
                  {filterQuestions(questions, 'Melhoria').data.map(
                    (q, index) => (
                      <>
                        <Question
                          key={index}
                          title={q.statement}
                          options={q.options}
                          uuid={q.uuid}
                          type="radio"
                          resp={filterResp(q.uuid)}
                          setResp={() => {}}
                          typeButtonFile={true}
                          evidence={filterEvidence(q.uuid)}
                          updateEvidence={handlePdfView}
                        />
                        <br />
                      </>
                    )
                  )}
                </>
              </TabPanel> */}
            </SwipeableViews>
          </Box>
        )
      ) : filter ? (
        <Table
          rows={rows.filter(params => params.answers.length > 0)}
          columns={columns}
          handleSubmit={handleSubmit}
        />
      ) : (
        <Table rows={rows} columns={columns} handleSubmit={handleSubmit} />
      )}

      <Box sx={{ display: 'flex', gap: 1 }}>
        {value !== 0 ? (
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => moveAnt()}
          >
            Anterior
          </Button>
        ) : (
          <></>
        )}
        {value !== 6 && uniqueId !== null && (
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={() => moveProx()}
          >
            Proximo
          </Button>
        )}
      </Box>

      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={handleDelete}
        success={() => {
          setOpenAlert(false)
          window.location.reload()
        }}
      />

      <PDFModal
        base64String={pdfString}
        isOpen={isOpenPdfModal}
        onClose={() => setIsOpenPdfModal(false)}
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.Modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Atribuir este Formulário
          </Typography>
          <Typography id="modal-modal-description">
            Selecione os Avaliadores que serão responsáveis por este formulário.
          </Typography>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-name-label">Name</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              sx={{ width: '100%' }}
              value={personName}
              onChange={e => {
                setPersonName(e.target.value)
              }}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
            >
              {listSpecialists.map(object => (
                <MenuItem key={object.uuid} value={object.uuid}>
                  {object.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            fullWidth
            sx={{ marginTop: 1 }}
            size="large"
            type="submit"
            variant="contained"
            onClick={specialistAssignment}
          >
            Atribuir
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}

export default index
